/* eslint-disable react-hooks/exhaustive-deps */
import { PaginatedResponse } from '@eagle/api-types';
import { InputTypes, isPropertyInObjectFormat, Json, JsonObject, PropertiesDefinition, PropertyDefinition, ReferencePropertyDefinition, RoleFunction, TypeDefinitionTypes } from '@eagle/common';
import { LockOpenSharp, LockSharp } from '@mui/icons-material';
import { Autocomplete, Box, IconButton, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { useDebounce } from '@react-hook/debounce';
import { isEmpty, isString, isUndefined } from 'lodash';
import { DateTime } from 'luxon';
import React, { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createUseStyles } from 'react-jss';
import { useAuthenticated } from '../../auth';
import { API_CALL_TEXT_LENGTH, DEFAULT_TIME_ZONE, LOOKUP_DEBOUNCE_TIME } from '../../constants';
import { useFetchOneCache, usePromise } from '../../hooks';
import { CommonEntity } from '../../types';
import { FILTER_OUT, testid, useHasAuthorization } from '../../util';
import { DatePicker } from '../date-time-range-picker/date-picker';
import { EmailEditor } from '../email-editor';
import { getMaybePropertyValue, isPropertyAuthoritative, referenceCollectionToCacheType } from '../format';
import { NumberEditor } from '../number-editor';
import { PhoneInput } from '../phone-input';
import { SwitchEditor } from '../switch-editor';
import { TextEditor } from '../text-editor';

const MULTILINE_INPUT_ROWS = 3;
const SYSTEM_ADMINISTRATOR_ROLE = [RoleFunction.SYSTEM_ADMINISTRATOR] as const;

const useStyles = createUseStyles({
  button: {
    border: 'none',
    borderRadius: '1rem',
    height: '1rem',
    lineHeight: 1,
    margin: 0,
    padding: 0,
    verticalAlign: 'middle',
    width: '1rem',
  },
  buttonIcon: {
    verticalAlign: 'unset',
  },
  column: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 'calc(0.375rem + 1px)',
    paddingBottom: 'calc(0.375rem + 1px)',
    wordWrap: 'break-word',
  },
  arrayPropertyContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
});

const SUPPORTED_PROPERTY_TYPES = [
  TypeDefinitionTypes.BOOLEAN,
  TypeDefinitionTypes.DATE,
  TypeDefinitionTypes.EMAIL,
  TypeDefinitionTypes.INTEGER,
  TypeDefinitionTypes.NUMBER,
  TypeDefinitionTypes.PHONE,
  TypeDefinitionTypes.TEXT,
  TypeDefinitionTypes.REFERENCE,
];

interface AuthoritativeButtonProps {
  disabled: boolean;
  onClick?: () => void;
  open: boolean;
}

export interface PropertiesElementProps {
  'data-testid'?: string;
  disabled?: boolean;
  properties: JsonObject;
  propertyDefinitions: PropertiesDefinition;
  onChange: (properties: JsonObject) => void;
  size?: 'small' | 'medium';
  required?: boolean;
  sharedThingTypeId?: string;
}

export const PropertiesElement: FC<PropertiesElementProps> = ({ disabled, properties, propertyDefinitions, onChange, size, required, sharedThingTypeId, ...props }) => {
  const { t } = useTranslation(['common']);
  const classes = useStyles();
  const { hasAuthorization } = useHasAuthorization();
  const hasSystemAdminPermissions = hasAuthorization(SYSTEM_ADMINISTRATOR_ROLE);
  const [unlockedProperties, setUnlockedProperties] = useState<Record<string, boolean>>({});

  const [propertiesState, setPropertiesState] = useState(properties as Record<string, unknown>);

  useEffect(() => {
    if (disabled) return;

    setUnlockedProperties({});
  }, [disabled]);

  const getDependentProperties = (property: string): string[] => {
    const output: string[] = [];
    for (const field of propertyDefinitions.order) {
      const definition = propertyDefinitions.definitions[field];
      if (definition && isReferenceProperty(definition) && definition.propertyDependencies.includes(property)) {
        output.push(field, ...getDependentProperties(field));
      }
    }
    return output;
  };

  const handleChange = (property: string) => (value: unknown): Promise<void> => {
    setPropertiesState((state) => {
      const dependentFields = getDependentProperties(property);

      const valueOrValueObject = state[property];
      const isObjectFormat = isPropertyInObjectFormat(valueOrValueObject);
      if (value as string === '' || (typeof value === 'object' && isEmpty(value))) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [property]: _, ...newState } = state;
        for (const dependentField of dependentFields) {
          delete newState[dependentField];
        }
        return newState;
      }
      const newState = { ...state, [property]: isObjectFormat ? { ...valueOrValueObject, value } : { value } };
      for (const dependentField of dependentFields) {
        delete newState[dependentField];
      }
      return newState;
    });
    return Promise.resolve();
  };

  useEffect(() => {
    onChange(propertiesState as JsonObject);
  }, [propertiesState]);

  useEffect(() => {
    setPropertiesState(properties);
  }, [properties]);

  const isSupportedProperty = (property: string): boolean => {
    return SUPPORTED_PROPERTY_TYPES.includes(propertyDefinitions.definitions[property].type);
  };

  const isArrayLikeProperty = (property: string): boolean => {
    return !!propertyDefinitions.definitions[property].multiple;
  };

  const isPropertyOfType = (property: string, type: TypeDefinitionTypes): boolean => {
    return propertyDefinitions.definitions[property].type === type;
  };

  const handleArrayPropertyUpdate = (property: string) => async (value: string): Promise<void> => {
    try {
      await handleChange(property)(JSON.parse(value) as Json);
    } catch (e) {
      setPropertiesState({ ...propertiesState, [property]: value });
      setTimeout(() => {
        setPropertiesState({ ...propertiesState, [property]: properties[property] });
      }, 0);
    }
  };

  const getPropertyValueByName = (name: string): unknown => {
    const rawPropertyValue = propertiesState[name];
    return getMaybePropertyValue(rawPropertyValue);
  };

  const getPropertyEditor = (property: string, index: number): ReactNode => {
    const rawPropertyValue = propertiesState[property];
    const isUnlocked = !!unlockedProperties[property];
    const isAuthoritative = isPropertyAuthoritative(rawPropertyValue);
    const value = getMaybePropertyValue(rawPropertyValue);

    const isDisabled = disabled || (isAuthoritative && !isUnlocked);
    const inputIcon = isAuthoritative
      ? <AuthoritativeButton
        disabled={!hasSystemAdminPermissions}
        onClick={() => setUnlockedProperties((prev) => ({ ...prev, [property]: !prev[property] }))}
        open={isUnlocked}
      />
      : undefined;

    if (!isSupportedProperty(property)) {
      return <span>{isUndefined(value) ? '' : String(value)}</span>;
    }

    if (isArrayLikeProperty(property)) {
      const arrayValue = isUndefined(value) ? '' : isString(value) ? value : JSON.stringify(value);

      return (
        <div key={index} className={classes.arrayPropertyContainer}>
          <TextEditor
            disabled={isDisabled}
            inputProps={{ endAdornment: inputIcon }}
            data-testid={`property-element-${index}`}
            value={arrayValue}
            placeholder={getPlaceholder(value)}
            onChange={handleArrayPropertyUpdate(property)}
            aria-describedby="arrayHelpBlock"
            size={size}
          />
          <Typography variant='body1' id="arrayHelpBlock">
            {isPropertyOfType(property, TypeDefinitionTypes.TEXT) && t('common:common.hint.array', { example: '["item_1", "item_2"]' })}
            {isPropertyOfType(property, TypeDefinitionTypes.BOOLEAN) && t('common:common.hint.array', { example: '[true, false]' })}
            {(isPropertyOfType(property, TypeDefinitionTypes.NUMBER) || isPropertyOfType(property, TypeDefinitionTypes.INTEGER)) && t('common:common.hint.array', { example: '[1, 2]' })}
          </Typography>
        </div>
      );
    }

    switch (propertyDefinitions.definitions[property].type) {
      case TypeDefinitionTypes.TEXT: {
        const isMultiline = propertyDefinitions.definitions[property].input === InputTypes.MULTILINE;

        return <TextEditor
          key={index}
          data-testid={testid`${propertyDefinitions.definitions[property].label}-input`}
          disabled={isDisabled}
          inputProps={{ endAdornment: inputIcon }}
          label={propertyDefinitions.definitions[property].label}
          multiline={isMultiline}
          onChange={handleChange(property)}
          placeholder={getPlaceholder(value)}
          required={required}
          rows={isMultiline ? MULTILINE_INPUT_ROWS : undefined}
          size={size}
          value={isUndefined(value) ? '' : String(value)}
        />;
      }
      case TypeDefinitionTypes.BOOLEAN:
        return <SwitchEditor
          data-testid={testid`${propertyDefinitions.definitions[property].label}-switch`}
          key={index}
          disabled={isDisabled}
          icon={inputIcon}
          isChecked={Boolean(value)}
          label={propertyDefinitions.definitions[property].label}
          onChange={handleChange(property)}
          required={required}
          size={size}
        />;
      case TypeDefinitionTypes.DATE:
        return <DatePicker
          data-testid={`${testid`${propertyDefinitions.definitions[property].label}`}-date-picker`}
          key={index}
          date={isUndefined(value) ? null : DateTime.fromISO(String(value), { zone: DEFAULT_TIME_ZONE })}
          disabled={isDisabled}
          label={propertyDefinitions.definitions[property].label}
          onChange={handleChange(property)}
          // We need `pointerEvents: 'auto'` because disabled buttons can't have tooltips otherwise
          OpenPickerButtonProps={isAuthoritative ? { style: { pointerEvents: 'auto' } } : undefined}
          OpenPickerIcon={isAuthoritative ? AuthoritativeButton : undefined}
          placeholder={getPlaceholder(value)}
          required={required}
          size={size}
        />;
      case TypeDefinitionTypes.EMAIL:
        return <EmailEditor
          data-testid={`${testid`${propertyDefinitions.definitions[property].label}`}-email-input`}
          key={index}
          disabled={isDisabled}
          endAdornment={inputIcon}
          label={propertyDefinitions.definitions[property].label}
          onChange={handleChange(property)}
          placeholder={getPlaceholder(value)}
          required={required}
          size={size}
          value={isUndefined(value) ? '' : String(value)}
        />;
      case TypeDefinitionTypes.NUMBER:
      case TypeDefinitionTypes.INTEGER:
        return <NumberEditor
          data-testid={`${testid`${propertyDefinitions.definitions[property].label}`}-number-input`}
          key={index}
          disabled={isDisabled}
          InputProps={{ endAdornment: inputIcon }}
          label={propertyDefinitions.definitions[property].label}
          onChange={handleChange(property)}
          placeholder={getPlaceholder(value)}
          required={required}
          size={size}
          value={isUndefined(value) ? '' : Number(value)}
        />;
      case TypeDefinitionTypes.PHONE:
        return <PhoneInput
          data-testid={`${testid`${propertyDefinitions.definitions[property].label}`}-phone-input`}
          key={index}
          disabled={isDisabled}
          disableFormatting
          InputProps={{ endAdornment: inputIcon }}
          label={propertyDefinitions.definitions[property].label}
          onChange={handleChange(property)}
          size={size}
          value={isUndefined(value) ? '' : String(value)}
        />;
      case TypeDefinitionTypes.REFERENCE: {
        if (!sharedThingTypeId) {
          return null;
        }
        return <ThingReferenceEditor
          key={index}
          definition={propertyDefinitions.definitions[property] as ReferencePropertyDefinition}
          disabled={isDisabled}
          getPropertyValueByName={getPropertyValueByName}
          icon={inputIcon}
          onChange={handleChange(property)}
          property={property}
          size={size}
          sharedThingTypeId={sharedThingTypeId}
          value={isUndefined(value) ? null : String(value)}
        />;
      }
      default: return <span></span>;
    }
  };

  const getPlaceholder = (value: any): string => {
    return isUndefined(value) ? t('common:component.properties.hint.not-set') : '';
  };

  if (!propertyDefinitions.order.length) return <></>;

  return <Stack direction='column' spacing={2} data-testid={props['data-testid']}>
    {propertyDefinitions.order.map((property, index) => getPropertyEditor(property, index))}
  </Stack>;
};

const AuthoritativeButton: FC<AuthoritativeButtonProps> = ({
  disabled = true,
  onClick,
  open = false,
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Tooltip
      title={
        disabled
          ? t('common:component.authoritative-flag.hint.tooltip')
          : open
            ? t('common:component.authoritative-flag.unlocked.tooltip')
            : t('common:component.authoritative-flag.locked.tooltip')
      }
    >
      <IconButton disabled={disabled} onClick={onClick}>
        {disabled || !open
          ? <LockSharp color={disabled ? 'disabled' : undefined} />
          : <LockOpenSharp />
        }
      </IconButton>
    </Tooltip>
  );
};

const isReferenceProperty = (propertyDefinition: PropertyDefinition): propertyDefinition is ReferencePropertyDefinition => {
  return propertyDefinition.type === TypeDefinitionTypes.REFERENCE;
};

interface ReferenceEditorProps {
  definition: ReferencePropertyDefinition;
  disabled?: boolean;
  getPropertyValueByName: (name: string) => unknown;
  icon?: React.ReactNode;
  onChange: (value: string | null) => void;
  property: string;
  size?: 'small' | 'medium';
  sharedThingTypeId: string;
  value: string | null;
}

const ThingReferenceEditor: FC<ReferenceEditorProps> = ({ value, onChange, disabled, definition, getPropertyValueByName, property, size, sharedThingTypeId, icon }) => {
  const { t } = useTranslation(['common']);
  const { axios } = useAuthenticated();
  const [searchValue, setSearchValue] = useDebounce('', LOOKUP_DEBOUNCE_TIME);
  const fetchOneCache = useFetchOneCache(referenceCollectionToCacheType[definition.referenceCollection]);

  const [selectedEntity] = usePromise(async () => {
    if (!value) {
      return null;
    }
    return fetchOneCache.one<CommonEntity>(value);
  }, [value]);

  const dependantProperties = useMemo(() => {
    const result: Record<string, { value: unknown }> = {};
    for (const dependency of definition.propertyDependencies) {
      result[dependency] = { value: getPropertyValueByName(dependency) };
    }
    return result;
  }, [definition, getPropertyValueByName]);

  const hasMissingDependency = Object.values(dependantProperties).some((property) => !property.value);
  const stringifiedDependencies = JSON.stringify(dependantProperties);

  const isSearchValueTooShort = searchValue.length > 0 && searchValue.length < API_CALL_TEXT_LENGTH;

  const [options, , status] = usePromise(async () => {
    if (hasMissingDependency) {
      return null;
    }
    if (isSearchValueTooShort) {
      return null;
    }
    return axios.get<PaginatedResponse<CommonEntity>>(`/api/v2/shared-thing-type/${sharedThingTypeId}/reference-lookup/${property}`, {
      params: {
        dependantProperties: JSON.parse(stringifiedDependencies) as Record<string, { value: unknown }>,
        filter: FILTER_OUT.deleted,
        search: searchValue || undefined,
      },
    });
  }, [axios, sharedThingTypeId, property, hasMissingDependency, stringifiedDependencies, searchValue, isSearchValueTooShort]);

  return (
    <Box sx={{ position: 'relative' }}>
      <Autocomplete
        disabled={disabled || hasMissingDependency}
        filterOptions={(option) => option}
        getOptionLabel={(option) => {
          const labelFromOptions = options?.data.items.find((opt) => opt._id === option)?.display;
          if (labelFromOptions) {
            return labelFromOptions;
          }
          if (option === value) {
            return selectedEntity?.display ?? '';
          }
          return '';
        }}
        loading={status === 'pending' || isSearchValueTooShort}
        loadingText={isSearchValueTooShort ? t('common:component.search.hint.less-than-count', { count: API_CALL_TEXT_LENGTH }) : t('common:common.labels.loading')}
        onChange={(_, val) => {
          onChange(val || '');
        }}
        noOptionsText={t('common:common.hint.list.no-results')}
        onInputChange={(_, inputValue, reason) => {
          setSearchValue(reason === 'reset' ? '' : inputValue);
        }}
        options={options?.data.items.map((option) => option._id) ?? []}
        popupIcon={icon ? null : undefined}
        disableClearable={!!icon}
        renderInput={(props) => <TextField label={definition.label} {...props} />}
        size={size}
        sx={{
          '& .MuiAutocomplete-popupIndicator': {
            pointerEvents: 'auto',
          },
          '& .MuiAutocomplete-endAdornment': {
            paddingRight: '5px',
          },
        }}
        value={value}
      />
      {!!icon
        && <Box
          sx={{
            position: 'absolute',
            top: '50%',
            right: '14px',
            transform: 'translate(0, -50%)',
            overflow: 'auto',
          }}
        >
          {icon}
        </Box>
      }
    </Box>
  );
};
