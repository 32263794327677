import { RoleFunction } from '@eagle/common';
import { ChangeAction, CreateAccountFromTemplateRequest, CreateAccountRequestRequest, InitialUser, LifecycleTemplate, SelfClaimRequest, SetSharedThingLifeCycleStageRequest, Stage, Thing, ThingLifeCycleStateResponse } from '@eagle/core-data-types';
import { Button, Typography } from '@mui/material';
import { useTheme } from '@mui/system';
import { AxiosError } from 'axios';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { FC, ReactNode, SetStateAction, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Issue } from 'validata';
import validator from 'validator';
import { useAuthenticated } from '../../../auth';
import { useApiErrorHandler, useFetchOneCache, useSwitchAwareConfig } from '../../../hooks';
import { CacheDataTypes, Nullable, Undefinable } from '../../../types';
import { useHasAuthorization } from '../../../util';
import { DialogActions, DialogCloseButton, DialogContent, DialogInner, DialogRoot, DialogTitle } from '../../dialog';
import { useBoolFlag } from '../../flags';
import { convertPropertiesToObjectFormat } from '../../format';
import { LoadingButton } from '../../loading-button';
import { StageConfirmDialogConfirmStep } from './stage-confirm-dialog-confirm-step';
import { stageConfirmDialogContext } from './stage-confirm-dialog-context';
import { StageConfirmDialogEditStep } from './stage-confirm-dialog-edit-step';
import { initStageConfirmDialogReducer, StageConfirmDialogConfig, stageConfirmDialogReducer, StageConfirmDialogStep, StakeholderState } from './stage-confirm-dialog-reducer';

const ACCOUNT_CREATOR = [RoleFunction.ACCOUNT_CREATOR] as const;

interface StageConfirmDialogProps {
  'data-testid'?: string;
  dialogTitle?: string;
  lifecycleTemplate: LifecycleTemplate;
  lifeCycleState: ThingLifeCycleStateResponse;
  onClose: VoidFunction;
  onBack?: VoidFunction;
  onSuccess?: (hasDeleteAction: boolean) => void;
  open: boolean;
  stageId: string;
  stakeholderRoles: string[];
  thing?: Thing;
  config?: StageConfirmDialogConfig;
  sharedThingId: string;
}

export const StageConfirmDialog: FC<StageConfirmDialogProps> = ({
  stageId,
  lifecycleTemplate,
  open,
  'data-testid': dataTestId,
  onClose,
  onBack,
  ...rest
}) => {
  const stage = lifecycleTemplate.stages.find((stage) => stage.stageId === stageId);
  if (!stage) {
    return null;
  }

  return (
    <DialogRoot
      data-testid={dataTestId}
      fullWidth
      maxWidth="xs"
      onClose={onClose}
      open={open}
      scroll="paper"
    >
      <DialogCloseButton onClick={onClose} />
      <StageConfirmDialogInner
        lifecycleTemplate={lifecycleTemplate}
        stage={stage}
        onClose={onClose}
        onBack={onBack}
        {...rest}
      />
    </DialogRoot>
  );
};

interface StageConfirmDialogInnerProps extends Omit<StageConfirmDialogProps, 'open' | 'stageId'> {
  stage: Stage;
}

const StageConfirmDialogInner: FC<StageConfirmDialogInnerProps> = ({
  stage,
  lifecycleTemplate,
  lifeCycleState,
  sharedThingId,
  thing,
  onClose,
  onBack,
  onSuccess,
  stakeholderRoles,
  config,
  dialogTitle,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(['common', 'admin']);
  const theme = useTheme();
  const { restClient } = useAuthenticated();
  const { handleCreateError } = useApiErrorHandler();
  const { hasAuthorization } = useHasAuthorization();
  const accountCreator = hasAuthorization(ACCOUNT_CREATOR);
  const hasUserFeature = useBoolFlag('manage-account-create-invite-user-feature');
  const domainConfig = useSwitchAwareConfig();
  const hasEThingsAuthProvider = Boolean(domainConfig.ethingsAuthenticationProvider);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(false);
  const thingCache = useFetchOneCache(CacheDataTypes.THING);
  const [state, dispatch] = useReducer(stageConfirmDialogReducer, { lifeCycleState, lifecycleTemplate, stakeholderRoles, config, stage }, initStageConfirmDialogReducer);

  const setActiveStep = (step: SetStateAction<number>): void => {
    dispatch({ type: 'SET_STEP', step: typeof step === 'function' ? step(state.currentStep) : step });
  };

  const createStakeholderAccount = async (
    stakeholder: StakeholderState,
    accountCreator: boolean,
    hasUserFeature: boolean,
    hasEThingsAuthProvider: boolean,
  ): Promise<string | null> => {
    if (!stakeholder.createAccount) return null;

    const users = getInitialAccountUsers(stakeholder.createAccount.data.users, hasUserFeature, hasEThingsAuthProvider);
    let newAccount;

    if (accountCreator) {
      const data: CreateAccountFromTemplateRequest = {
        ...stakeholder.createAccount.data,
        properties: convertPropertiesToObjectFormat(stakeholder.createAccount.data.properties),
        users,
      };
      newAccount = await restClient.accountTemplate.createAccount(stakeholder.createAccount.accountTemplateId, data);
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { users: _, ...targetAccountInfo } = stakeholder.createAccount.data;
      const data: CreateAccountRequestRequest = {
        accountTemplateId: stakeholder.createAccount.accountTemplateId,
        labels: {},
        tags: [],
        targetAccountInfo: {
          ...targetAccountInfo,
          initialUsers: users,
          properties: convertPropertiesToObjectFormat(targetAccountInfo.properties),
        },
      };
      newAccount = await restClient.accountRequest.create(data);
    }

    dispatch({ type: 'SET_CREATED_ACCOUNT_ID', role: stakeholder.role, createdAccountId: newAccount._id });
    return newAccount._id;
  };

  const getStakeholdersForStageTransition = async (): Promise<Nullable<Record<string, Nullable<string>>>> => {
    const stakeholders: Record<string, Nullable<string>> = {};

    for (const stakeholder of state.stakeholders) {
      if (stakeholder.isCreatingNewAccount && stakeholder.createAccount) {
        if (stakeholder.createAccount.createdAccountId) {
          stakeholders[stakeholder.role] = stakeholder.createAccount.createdAccountId;
          continue;
        }

        try {
          // eslint-disable-next-line no-await-in-loop
          const accountId = await createStakeholderAccount(
            stakeholder,
            accountCreator,
            Boolean(hasUserFeature),
            hasEThingsAuthProvider,
          );

          if (!accountId) { throw new Error('StakeholderAccount could not be created.'); }
          stakeholders[stakeholder.role] = accountId;
        } catch (err) {
          console.log('err', err);
          handleCreateError(err, CacheDataTypes.ACCOUNT, stakeholder.createAccount.data.display);
          return null;
        }
      }

      else {
        if (stakeholder.accountId && accountCreator) {
          // eslint-disable-next-line no-await-in-loop
          const stakeholderAccount = await restClient.sharedThing.getStakeholderAccount(sharedThingId, stakeholder.role, stakeholder.accountId);
          if (stakeholderAccount.isAccountRequest) {
            try {
              // eslint-disable-next-line no-await-in-loop
              const accountRequest = await restClient.accountRequest.get(stakeholder.accountId);
              if (domainConfig.productName === accountRequest.productName) {
                // eslint-disable-next-line no-await-in-loop
                await restClient.accountRequest.createAccount(stakeholder.accountId);
              }
            } catch (err) {
              handleCreateError(err, CacheDataTypes.ACCOUNT, stakeholderAccount.display);
              return null;
            }
          }
        }
        if (stakeholder.isDirty || stakeholder.isRemoveConfirmationChecked) {
          if (stakeholder.initialAccountId) {
            stakeholders[stakeholder.role] = stakeholder.isRemoveConfirmationChecked ? null : stakeholder.accountId ?? null;
          }
          else if (stakeholder.accountId) {
            stakeholders[stakeholder.role] = stakeholder.accountId;
          }
        }
      }
    }
    return stakeholders;
  };

  const persistSelfClaim = async (): Promise<void> => {
    const stakeholders: Record<string, Nullable<string>> = {};

    const stakeholderPromises = state.stakeholders.map(async (stakeholder) => {
      if (stakeholder.isCreatingNewAccount && stakeholder.createAccount) {
        if (stakeholder.createAccount.createdAccountId) {
          return { role: stakeholder.role, accountId: stakeholder.createAccount.createdAccountId };
        }
        try {
          const accountId = await createStakeholderAccount(
            stakeholder,
            accountCreator,
            Boolean(hasUserFeature),
            hasEThingsAuthProvider,
          );
          if (!accountId) { throw new Error('StakeholderAccount could not be created.'); }
          return { role: stakeholder.role, accountId };
        } catch (err) {
          console.log('err', err);
          handleCreateError(err, CacheDataTypes.ACCOUNT, stakeholder.createAccount.data.display);
          throw err;
        }
      } else if (stakeholder.accountId) {
        return { role: stakeholder.role, accountId: stakeholder.accountId };
      }
      return null;
    });

    const results = await Promise.all(stakeholderPromises);
    results.forEach((result) => {
      if (result) {
        stakeholders[result.role] = result.accountId;
      }
    });

    try {
      const currentUserRole = stakeholderRoles[0]?.toLowerCase();
      if (Object.keys(stakeholders).length === 0 && !((state.config?.isSelfClaim ?? false) || (currentUserRole && state.stakeholders.some((s) => s.role.toLowerCase() === currentUserRole)))) {
        throw new Error('No stakeholders found.');
      }

      const selfClaimRequest: SelfClaimRequest = {
        stakeholders,
        devices: null,
        deviceChecks: null,
        transition: null,
        stageProperties: null,
      };

      await restClient.sharedThing.selfClaim(sharedThingId, selfClaimRequest);

      showSuccessSnackbar();
    } catch (err) {
      handleCreateError(err, CacheDataTypes.SELF_CLAIM, 'self-claim');
    }
  };

  const showSuccessSnackbar = (): void => {
    enqueueSnackbar(
      config?.successMessage ??
      t('admin:page.thing-detail.update-stage-dialog.hint.success', {
        thingDisplay: thing?.display ?? '',
        stageDisplay: stage.display,
      }),
      { variant: 'success' },
    );
  };

  const handleConfirm = async (): Promise<void> => {
    try {
      dispatch({ type: 'START_SUBMIT' });

      if (config?.isSelfClaim) {
        await persistSelfClaim();

        onClose();
        return;
      }

      const stakeholders = await getStakeholdersForStageTransition();

      if (stakeholders === null) {
        return;
      }

      const body: SetSharedThingLifeCycleStageRequest = {
        devices: null,
        deviceChecks: null,
        stakeholders,
        stageId: stage.stageId,
        transition: null,
        stageProperties: null,
      };

      await restClient.sharedThing.setLifeCycleStage(sharedThingId, body);

      showSuccessSnackbar();

      if (thing) {
        await Promise.all([
          thingCache.invalidate(thing._id),
        ]);
      }

      const hasDeleteAction = stakeholderRoles.every(
        (role) => lifecycleTemplate.stakeholderAccounts[role].thingUninstallAction?.action === ChangeAction.DELETE,
      );
      onSuccess?.(hasDeleteAction);
      onClose();
    } catch (err) {
      const issues = (err as AxiosError<{ issues?: Issue[] }>).response?.data.issues;
      const path = issues?.[0]?.path;
      const reason = issues?.[0]?.reason;

      enqueueSnackbar(
        t('admin:page.thing-detail.update-stage-dialog.hint.error', {
          path,
          reason,
          thingDisplay: thing?.display ?? '',
          stageDisplay: stage.display,
        }),
        { variant: 'error' },
      );
      throw err;
    } finally {
      dispatch({ type: 'SUBMIT_FINISHED' });
    }
  };

  const handleNext = (): void => {
    const isRemoveConfirmationChecked = Boolean(state.stakeholders.find((stakeholder) => stakeholder.isRemoveConfirmationChecked));
    setActiveStep(state.removeConfirmation && isRemoveConfirmationChecked ? StageConfirmDialogStep.confirmRemove : StageConfirmDialogStep.confirm);
  };

  const renderActions = (): JSX.Element[] => {
    const actions: JSX.Element[] = [];

    // Add back button for self-claim or update stage (mutually exclusive)
    if (config?.isSelfClaim && onBack) {
      actions.push(
        <Button
          key="back"
          data-testid="back-button"
          onClick={onBack}
          variant="outlined"
        >
          {t('common:common.action.back')}
        </Button>,
      );
    } else if (state.currentStep === StageConfirmDialogStep.confirm) {
      actions.push(
        <Button
          key="back"
          data-testid="back-button"
          disabled={state.isSubmitting}
          onClick={() => dispatch({ type: 'SET_STEP', step: StageConfirmDialogStep.edit })}
          variant="outlined"
        >
          {t('common:common.action.back')}
        </Button>,
      );
    }

    actions.push(
      <Button
        key="cancel"
        onClick={onClose}
        variant="outlined"
      >
        {t('common:common.action.cancel')}
      </Button>,
    );

    if (state.currentStep === StageConfirmDialogStep.confirm) {
      actions.push(
        <LoadingButton
          key="confirm"
          data-testid="stage-dialog-update-stage-button"
          disabled={!state.confirmationChecked}
          isLoading={state.isSubmitting}
          loadingCaption={t('common:common.labels.loading')}
          onClick={async () => {
            try {
              await handleConfirm();
            } catch (error) {
              console.error('Error in onClick handler:', error);
            }
          }}
          variant="contained"
        >
          {config?.isSelfClaim
            ? t('common:common.action.confirm')
            : config?.updateStageLabel ?? t('admin:page.thing-detail.update-stage-dialog.action.update-stage')}
        </LoadingButton>,
      );
    }

    if (state.currentStep === StageConfirmDialogStep.confirmRemove) {
      actions.push(
        <LoadingButton
          key="confirm-remove"
          data-testid="stage-dialog-update-stage-button"
          loadingCaption={t('common:common.labels.loading')}
          size="medium"
          task={handleConfirm}
          variant="contained"
        >
          {t('common.action.confirm')}
        </LoadingButton>,
      );
    }

    if (state.currentStep === StageConfirmDialogStep.edit) {
      actions.push(
        <Button
          key="next"
          data-testid="stage-dialog-next-button"
          disabled={
            isNextButtonDisabled ||
            state.stakeholders.some(
              (stakeholder) =>
                !isStakeholderStateValid(stakeholder) ||
                (stakeholder.config?.requiredToChange &&
                  !stakeholder.isDirty &&
                  !stakeholder.isRemoveConfirmationChecked),
            )
          }
          onClick={handleNext}
          variant="contained"
        >
          {t('common:common.action.next')}
        </Button>,
      );
    }

    return actions;
  };

  const contentByStep: Record<StageConfirmDialogStep, ReactNode> = {
    [StageConfirmDialogStep.edit]: <StageConfirmDialogEditStep setIsNextButtonDisabled={setIsNextButtonDisabled} />,
    [StageConfirmDialogStep.confirm]: <StageConfirmDialogConfirmStep />,
    [StageConfirmDialogStep.confirmRemove]: <Typography>{state.removeConfirmation?.confirmText}</Typography>,
  };

  const subtitleByStep: Record<StageConfirmDialogStep, Undefinable<string>> = {
    [StageConfirmDialogStep.edit]: undefined,
    [StageConfirmDialogStep.confirm]: t('admin:page.thing-detail.update-stage-dialog.confirm.sub-title'),
    [StageConfirmDialogStep.confirmRemove]: undefined,
  };

  const title = dialogTitle ?? t('admin:page.thing-detail.update-stage-dialog.heading', { stageDisplay: stage.display });

  return (
    <DialogInner sx={{ maxHeight: `calc(100vh - ${theme.spacing(8)})` }}>
      <DialogTitle title={title} subtitle={subtitleByStep[state.currentStep]} />
      <DialogContent>
        <stageConfirmDialogContext.Provider value={{ state, dispatch, lifecycleTemplate, sharedThingId, stage, thing }}>
          {contentByStep[state.currentStep]}
        </stageConfirmDialogContext.Provider>
      </DialogContent>
      <DialogActions>
        {renderActions()}
      </DialogActions>
    </DialogInner>
  );
};

const getInitialAccountUsers = (users: Nullable<InitialUser[]>, hasUserFeature: boolean, hasEthingsAuthProvider: boolean): Nullable<InitialUser[]> => {
  if (!users || !hasUserFeature) return null;
  const validUsers = users.filter((value) => value.email.trim() !== '').map((item) => _.pick(item, ['display', 'email']));

  if (!hasEthingsAuthProvider) {
    return validUsers.map((item) => ({ email: item.email }));
  }

  return validUsers;
};

const isStakeholderStateValid = (stakeholderState: StakeholderState): boolean => {
  if (stakeholderState.isEditing) {
    return false;
  }

  if (stakeholderState.isCreatingNewAccount) {
    if (!stakeholderState.createAccount?.data.display.trim() || (stakeholderState.createAccount.isCustomHomeDomain && !stakeholderState.createAccount?.data.homeDomain?.trim())) {
      return false;
    }
    if (stakeholderState.createAccount?.data.users) {
      for (const user of stakeholderState.createAccount.data.users) {
        if (user.email && !validator.isEmail(user.email)) {
          return false;
        }
      }
    }
  }

  else {
    if (stakeholderState.isRequired) {
      return !!stakeholderState.accountId;
    }
  }

  return true;
};
