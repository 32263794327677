import { CopyAll } from '@mui/icons-material';
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { LatLng } from 'leaflet';
import { enqueueSnackbar } from 'notistack';
import { FC, ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Nullable } from '../../types';
import { ErrorMessage } from '../error-message';

export interface MapLinkMenuService {
  label: string;
  link: string;
  icon?: ReactNode;
}

interface Props {
  anchorEl: Nullable<HTMLElement>;
  latLng: LatLng;
  services: MapLinkMenuService[];
  hasCopy?: boolean;
  onClose: () => void;
  'aria-label'?: string;
  'data-testid'?: string;
}

export const MapLinksMenu: FC<Props> = ({ anchorEl, latLng, services, hasCopy, onClose, ...props }) => {
  const openMenu = Boolean(anchorEl);
  const { t } = useTranslation(['common']);

  const handleMenuClick = (formattedLink: string) => {
    window.open(formattedLink, '_blank');
    onClose();
  };

  const handleCopyClick = async () => {
    if (!latLng) return;

    try {
      await navigator.clipboard.writeText(`${latLng.lat},${latLng.lng}`);

      enqueueSnackbar(t('common:component.map-links-menu.labels.copied-success'), { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(<ErrorMessage error={error as Error} />, { variant: 'error' });
    } finally {
      onClose();
    }
  };

  const formattedServices = useMemo(() =>
    services.map((service) => ({
      ...service,
      formattedLink: service.link.replace('{latLng}', `${latLng.lat},${latLng.lng}`),
    })), [services, latLng],
  );

  return (
    <Menu anchorEl={anchorEl} open={openMenu} onClose={onClose} aria-label={props['aria-label']} data-testid={props['data-testid']}>
      {formattedServices.map((service) => (
        <MenuItem key={service.label} onClick={() => handleMenuClick(service.formattedLink)}>
          {service.icon && <ListItemIcon>{service.icon}</ListItemIcon>}
          <ListItemText primary={service.label} />
        </MenuItem>
      ))}
      {hasCopy && (
        <MenuItem onClick={handleCopyClick}>
          <ListItemIcon><CopyAll /></ListItemIcon>
          <ListItemText primary={t('common:component.map-links-menu.labels.copy')} />
        </MenuItem>
      )}
    </Menu>
  );
};
